import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import SEO from "@components/seo"

import { detectMob } from "@src/Helper"

function TermsOfService() {
  // STATES
  const [id, setId] = useState("terms-services")

  // EFFECTS
  useEffect(() => {
    if (detectMob()) {
      setId("m-terms-services")
    }
  }, [])

  return (
    <section id={id} className="section-150">
      <SEO title="Terms of Service" />
      <div className="container">
        <div style={{ marginBottom: 50 }} className="text-center">
          <div className="title">Terms of Service</div>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">1. Introduction</h3>
          <p>
            Welcome to PT. Eksekutif Media Utama (“Company”, “we”, “our”, “us”)!
            <br />
            <br />
            These Terms of Service (“Terms”, “Terms of Service”) govern your use
            of our website located at www.executivemafia.com and mobile
            application (together or individually “Service”) operated by PT.
            Eksekutif Media Utama.
            <br />
            <br />
            Our Privacy Policy also governs your use of our Service and explains
            how we collect, safeguard and disclose information that results from
            your use of our website and mobile applications.
            <br />
            <br />
            Your agreement with us includes these Terms and our Privacy Policy
            (“Agreements”). You acknowledge that you have read and understood
            Agreements, and agree to be bound of them.
            <br />
            <br />
            If you do not agree with (or cannot comply with) Agreements, then
            you may not use the Service, please let us know by emailing at
            support@executivemafia.com so we can try to find a solution. These
            Terms apply to all visitors, users and others who wish to access or
            use Service.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">About Executive Mafia</h3>
          <p>
            This website is owned and operated by PT. EKSEKUTIF MEDIA UTAMA. By
            visiting our website and accessing the information, resources,
            services, products, and tools we provide, you understand and agree
            to accept and adhere to the following terms and conditions as stated
            in this policy (hereafter referred to as ‘User Agreement’), along
            with the terms and conditions as stated in our site.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">
            Privacy Policy (please refer to the Privacy Policy section below for
            more information)
          </h3>
          <p>
            We reserve the right to change this User Agreement from time to time
            without notice. You acknowledge and agree that it is your
            responsibility to review this User Agreement periodically to
            familiarize yourself with any modifications. Your continued use of
            this site after such modifications will constitute acknowledgement
            and agreement of the modified terms and conditions.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">2. Communications</h3>
          <p>
            By using our Service, you agree to subscribe to newsletters,
            marketing or promotional materials and other information we may
            send. However, you may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link or by
            emailing at support@executivemafia.com.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">3. Purchases</h3>
          <p>
            If you wish to purchase any product or service made available
            through Service (“Purchase”), you may be asked to supply certain
            information relevant to your Purchase including but not limited to,
            your credit or debit card number, the expiration date of your card,
            your billing address, and your shipping information.
            <br />
            <br />
            You represent and warrant that: (i) you have the legal right to use
            any card(s) or other payment method(s) in connection with any
            Purchase; and that (ii) the information you supply to us is true,
            correct and complete.
            <br />
            <br />
            We may employ the use of third party services for the purpose of
            facilitating payment and the completion of Purchases. By submitting
            your information, you grant us the right to provide the information
            to these third parties subject to our Privacy Policy.
            <br />
            <br />
            We reserve the right to refuse or cancel your order at any time for
            reasons including but not limited to: product or service
            availability, errors in the description or price of the product or
            service, error in your order or other reasons.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">4. Contests, Sweepstakes and Promotions</h3>
          <p>
            Any contests, sweepstakes or other promotions (collectively,
            “Promotions”) made available through Service may be governed by
            rules that are separate from these Terms of Service. If you
            participate in any Promotions, please review the applicable rules as
            well as our Privacy Policy. If the rules for a Promotion conflict
            with these Terms of Service, Promotion rules will apply.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">5. Subscriptions</h3>
          <p>
            Some parts of Service are billed on a subscription basis
            ("Subscription(s)"). You will be billed in advance on a recurring
            and periodic basis ("Billing Cycle"). Billing cycles will be set
            depending on the type of subscription plan you select when
            purchasing a Subscription.
            <br />
            <br />
            At the end of each Billing Cycle, your Subscription will
            automatically renew under the exact same conditions unless you
            cancel it or PT. Eksekutif Media Utama cancels it. You may cancel
            your Subscription renewal either through your online account
            management page or by contacting support@executivemafia.com customer
            support team.
            <br />
            <br />
            A valid payment method is required to process the payment for your
            subscription. You shall provide us with accurate and complete
            billing information that may include but not limited to full name,
            address, state, postal or zip code, telephone number, and a valid
            payment method information. By submitting such payment information,
            you automatically authorize PT. Eksekutif Media Utama to charge all
            Subscription fees incurred through your account to any such payment
            instruments.
            <br />
            <br />
            Should automatic billing fail to occur for any reason, PT. Eksekutif
            Media Utama reserves the right to terminate your access to the
            Service with immediate effect.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">6. Free Service</h3>
          <p>
            We may, at its sole discretion, offer a Subscription with a free
            service for a limited period of time ("Free Service").
            <br />
            <br />
            You may be required to enter your billing information in order to
            sign up for Free Service.
            <br />
            <br />
            At any time and without notice, PT. Eksekutif Media Utama reserves
            the right to (i) modify Terms of Service of Free Service offer, or
            (ii) cancel such Free Service offer.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">7. Engagement Round</h3>
          <p>
            We may use the third-party app to support the engagement round as
            well as our current chat rooms.
            <br />
            <br />
            You may be required to enter your Telegram username and Instagram
            handle information in order to join the rounds.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">8. Fee Changes</h3>
          <p>
            PT. Eksekutif Media Utama, in its sole discretion and at any time,
            may modify Subscription fees for the Subscriptions. Any Subscription
            fee change will become effective at the end of the then-current
            Billing Cycle.
            <br />
            <br />
            Your continued use of Service after Subscription fee change comes
            into effect constitutes your agreement to pay the modified
            Subscription fee amount.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">9. Refunds</h3>
          <p>
            We issue refunds for Contracts within 3 days of the original
            purchase of the first time usage. Please read thoroughly,
            acknowledge, and agree to our{" "}
            <Link to="/refund-policy">Refund Policy.</Link>
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">10. Content</h3>
          <p>
            Our Service allows you to post, link, store, share and otherwise
            make available certain information, text, graphics, videos, or other
            material (“Content”). You are responsible for Content that you post
            on or through Service, including its legality, reliability, and
            appropriateness.
            <br />
            <br />
            By posting Content on or through Service, You represent and warrant
            that: (i) Content is yours (you own it) and/or you have the right to
            use it and the right to grant us the rights and license as provided
            in these Terms, and (ii) that the posting of your Content on or
            through Service does not violate the privacy rights, publicity
            rights, copyrights, contract rights or any other rights of any
            person or entity. We reserve the right to terminate the account of
            anyone found to be infringing on a copyright.
            <br />
            <br />
            You retain any and all of your rights to any Content you submit,
            post or display on or through Service and you are responsible for
            protecting those rights. We take no responsibility and assume no
            liability for Content you or any third party posts on or through
            Service. However, by posting Content using Service you grant us the
            right and license to use, modify, publicly perform, publicly
            display, reproduce, and distribute such Content on and through
            Service. You agree that this license includes the right for us to
            make your Content available to other users of Service, who may also
            use your Content subject to these Terms.
            <br />
            <br />
            PT. Eksekutif Media Utama has the right but not the obligation to
            monitor and edit all Content provided by users.
            <br />
            <br />
            In addition, Content found on or through this Service are the
            property of PT. Eksekutif Media Utama or used with permission. You
            may not distribute, modify, transmit, reuse, download, repost, copy,
            or use said Content, whether in whole or in part, for commercial
            purposes or for personal gain, without express advance written
            permission from us.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">11. Prohibited Uses</h3>
          <p>
            You may use Service only for lawful purposes and in accordance with
            Terms.
            <br />
            <br />
            You agree not to use Service:
          </p>
          <ol type="a">
            <li>
              In any way that violates any applicable national or international
              law or regulation.
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content or otherwise.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any “junk mail”, “chain letter,”
              “spam,” or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate Company, a Company
              employee, another user, or any other person or entity.
            </li>
            <li>
              In any way that infringes upon the rights of others, or in any way
              is illegal, threatening, fraudulent, or harmful, or in connection
              with any unlawful, illegal, fraudulent, or harmful purpose or
              activity.
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of Service, or which, as determined by us, may
              harm or offend Company or users of Service or expose them to
              liability.
            </li>
          </ol>
          <br />
          <br />
          <p>Additionally, you agree not to:</p>
          <ol type="a">
            <li>
              Use Service in any manner that could disable, overburden, damage,
              or impair Service or interfere with any other party’s use of
              Service, including their ability to engage in real time activities
              through Service.
            </li>
            <li>
              Use any robot, spider, or other automatic device, process, or
              means to access Service for any purpose, including monitoring or
              copying any of the material on Service.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on
              Service or for any other unauthorized purpose without our prior
              written consent.
            </li>
            <li>
              Use any device, software, or routine that interferes with the
              proper working of Service.
            </li>
            <li>
              Introduce any viruses, trojan horses, worms, logic bombs, or other
              material which is malicious or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of Service, the server on which Service is
              stored, or any server, computer, or database connected to Service.
            </li>
            <li>
              Attack Service via a denial-of-service attack or a distributed
              denial-of-service attack.
            </li>
            <li>Take any action that may damage or falsify Company rating.</li>
            <li>
              Otherwise attempt to interfere with the proper working of Service.
            </li>
          </ol>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">12. Analytics</h3>
          <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service. Please check our{" "}
            <Link to="/privacy-policy">
              privacy policy about how we collect and process Analytics data.
            </Link>
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">13. No Use by Minors</h3>
          <p>
            Service is intended only for access and use by individuals at least
            thirteen (13) years old. By accessing or using Service, you warrant
            and represent that you are at least thirteen (13) years of age and
            with the full authority, right, and capacity to enter into this
            agreement and abide by all of the terms and conditions of Terms. If
            you are not at least thirteen (13) years old, you are prohibited
            from both the access and usage of Service.
            <br />
            <br />
            Regarding privacy please read thoroughly, acknowledge to our{" "}
            <Link to="/privacy-policy">Privacy Policy.</Link>
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">14. Accounts</h3>
          <p>
            When you create an account with us, you guarantee that you are above
            the age of 13, and that the information you provide us is accurate,
            complete, and current at all times. Inaccurate, incomplete, or
            obsolete information may result in the immediate termination of your
            account on Service.
            <br />
            <br />
            You are responsible for maintaining the confidentiality of your
            account and password, including but not limited to the restriction
            of access to your computer and/or account. You agree to accept
            responsibility for any and all activities or actions that occur
            under your account and/or password, whether your password is with
            our Service or a third-party service. You must notify us immediately
            upon becoming aware of any breach of security or unauthorized use of
            your account.
            <br />
            <br />
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than you,
            without appropriate authorization. You may not use as a username any
            name that is offensive, vulgar or obscene.
            <br />
            <br />
            We reserve the right to refuse service, terminate accounts, remove
            or edit content, or cancel orders in our sole discretion.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">15. Intellectual Property</h3>
          <p>
            Service and its original content (excluding Content provided by
            users), features and functionality are and will remain the exclusive
            property of PT. Eksekutif Media Utama and its licensors. Service is
            protected by copyright, trademark, and other laws of and foreign
            countries. Our trademarks may not be used in connection with any
            product or service without the prior written consent of PT.
            Eksekutif Media Utama.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">16. Copyright Policy</h3>
          <p>
            We respect the intellectual property rights of others. It is our
            policy to respond to any claim that Content posted on Service
            infringes on the copyright or other intellectual property rights
            (“Infringement”) of any person or entity.
            <br />
            <br />
            If you are a copyright owner, or authorized on behalf of one, and
            you believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement, please submit your claim via
            email to support@executivemafia.com, with the subject line:
            “Copyright Infringement” and include in your claim a detailed
            description of the alleged Infringement as detailed below, under
            “DMCA Notice and Procedure for Copyright Infringement Claims”
            <br />
            <br />
            You may be held accountable for damages (including costs and
            attorneys’ fees) for misrepresentation or bad-faith claims on the
            infringement of any Content found on and/or through Service on your
            copyright.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">
            17. DMCA Notice and Procedure for Copyright Infringement Claims
          </h3>
          <p>
            You may submit a notification pursuant to the Digital Millennium
            Copyright Act (DMCA) by providing our Copyright Agent with the
            following information in writing (see 17 U.S.C 512(c)(3) for further
            detail):
          </p>
          <ol type="a">
            <li>
              An electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright’s interest;
            </li>
            <li>
              A description of the copyrighted work that you claim has been
              infringed, including the URL (i.e., web page address) of the
              location where the copyrighted work exists or a copy of the
              copyrighted work;
            </li>
            <li>
              Identification of the URL or other specific location on Service
              where the material that you claim is infringing is located;
            </li>
            <li>Your address, telephone number, and email address;</li>
            <li>
              A statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law;
            </li>
            <li>
              A statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner’s
              behalf.
            </li>
          </ol>
          <p>
            You can contact our Copyright Agent via email at
            support@executivemafia.com.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">18. Error Reporting and Feedback</h3>
          <p>
            You may provide us either directly at support@executivemafia.com or
            via third party sites and tools with information and feedback
            concerning errors, suggestions for improvements, ideas, problems,
            complaints, and other matters related to our Service (“Feedback”).
            You acknowledge and agree that: (i) you shall not retain, acquire or
            assert any intellectual property right or other right, title or
            interest in or to the Feedback; (ii) Company may have development
            ideas similar to the Feedback; (iii) Feedback does not contain
            confidential information or proprietary information from you or any
            third party; and (iv) Company is not under any obligation of
            confidentiality with respect to the Feedback. In the event the
            transfer of the ownership to the Feedback is not possible due to
            applicable mandatory laws, you grant Company and its affiliates an
            exclusive, transferable, irrevocable, free-of-charge,
            sub-licensable, unlimited and perpetual right to use (including
            copy, modify, create derivative works, publish, distribute and
            commercialize) Feedback in any manner and for any purpose.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">19. Links to Other Web Sites</h3>
          <p>
            Our Service may contain links to third party web sites or services
            that are not owned or controlled by PT. Eksekutif Media Utama.
            <br />
            <br />
            PT. Eksekutif Media Utama has no control over, and assumes no
            responsibility for the content, privacy policies, or practices of
            any third party web sites or services. We do not warrant the
            offerings of any of these entities/individuals or their websites.
            <br />
            <br />
            YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR
            LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
            ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON
            ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH
            THIRD PARTY WEB SITES OR SERVICES.
            <br />
            <br />
            WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
            POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">20. Disclaimer of Warranty</h3>
          <p>
            THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF
            ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES,
            OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND
            ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
            <br />
            <br />
            NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
            WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
            SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE
            ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES,
            THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
            THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT
            MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
            OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            <br />
            <br />
            COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
            OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
            ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
            PARTICULAR PURPOSE.
            <br />
            <br />
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">21. Withdrawal</h3>
          <p>
            You have the right to withdraw if you earned commission in the last
            three months. The minimum payout amount is USD 50, and the maximum
            amount is USD 500. The payout can only be requested once per 30
            days. The request will be processed every Monday and be sent via
            PayPal E-mail registered on your profile settings. Paypal’s Tax is
            included in the payout.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">22. Limitation of Liability</h3>
          <p>
            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
            DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
            PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT
            ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES
            OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY,
            WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN
            AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR
            ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
            WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE,
            ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL,
            STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF
            COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON
            THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE
            PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
            CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
            DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">23. Termination</h3>
          <p>
            We may terminate or suspend your account and bar access to Service
            immediately, without prior notice or liability, under our sole
            discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of Terms.
            <br />
            <br />
            If you wish to terminate your account, you may simply discontinue
            using our Service.
            <br />
            <br />
            All provisions of Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">24. Governing Law</h3>
          <p>
            This website is controlled by PT. Eksekutif Media Utama from our
            offices located in Indonesia. It can be accessed by most countries
            around the world. As each country has laws that may differ from
            those of Indonesia, by accessing our website, you agree that the
            statutes and laws of Indonesia, without regard to the conflict of
            laws and the United Nations Convention on the International Sales of
            Goods, will apply to all matters relating to the use of this website
            and the purchase of any products or services through this site.
            These Terms shall be governed and construed in accordance with the
            laws of Indonesia, which governing law applies to agreement without
            regard to its conflict of law provisions.
            <br />
            <br />
            Furthermore, any action to enforce this User Agreement shall be
            brought in the district court, or high court, or supreme court,
            located in Indonesia. You hereby agree to personal jurisdiction by
            such courts, and waive any jurisdictional, venue, or inconvenient
            forum objections to such courts.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">25. Changes to Service</h3>
          <p>
            We reserve the right to withdraw or amend our Service, and any
            service or material we provide via Service, in our sole discretion
            without notice. We will not be liable if for any reason all or any
            part of Service is unavailable at any time or for any period. From
            time to time, we may restrict access to some parts of Service, or
            the entire Service, to users, including registered users.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">26. Amendments to Terms</h3>
          <p>
            We may amend Terms at any time by posting the amended terms on this
            site. It is your responsibility to review these Terms periodically.
            <br />
            <br />
            Your continued use of the Platform following the posting of revised
            Terms means that you accept and agree to the changes. You are
            expected to check this page frequently so you are aware of any
            changes, as they are binding on you.
            <br />
            <br />
            By continuing to access or use our Service after any revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, you are no longer authorized to use
            Service.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">27. Acknowledgement</h3>
          <p>
            BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
            THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
            THEM.
          </p>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">28. Contact Us</h3>
          <p>
            If you have any questions or comments about these our Terms of
            Service as outlined above, you can Contact us by emailing{" "}
            <a href="mailto:support@executivemafia.com">
              support@executivemafia.com
            </a>{" "}
            or via mail to:
          </p>
          <p>
            PT. EKSEKUTIF MEDIA UTAMA
            <br />
            21, JALAN MELATI, KOMPLEK CEMARA ASRI
            <br />
            MEDAN CITY, NORTH SUMATRA 20371
            <br />
            INDONESIA
          </p>
        </div>
      </div>
    </section>
  )
}

export default TermsOfService
